$(document).ready(function() {


    // console.log("Hello from home-page-script.js");

    function RemoveInstallInstructionHome() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            $('#install-mobile-app').remove();
            $('#install-desktop-app').remove();
        }
    }
    RemoveInstallInstructionHome();




    // function iniHeroCubeAnimation() {

    //     if($('#js-hero-cube img').length) {

    //         if ($('#js-hero-cube').hasClass('hero-cube-img')) {

    //             $('#js-hero-cube img').remove();
    //             $('#js-hero-cube').append("<canvas id=\"herocanvas\"></canvas>");
    //             $('#js-hero-cube').removeClass('hero-cube-img');
    //             $('#js-hero-cube').addClass('hero-cube-canvas');

    //             // This Pen is a fork of kappeh's Pen 3D Rubik's Cube.
    //             // https://codepen.io/kappeh/details/bWmmVa
    //             var canvas;
    //             var ctx;
    //             var height;
    //             var width;
    //             var fov;
    //             var fovVertical;
    //             var fovHorizontal;
    //             var worldVelocity;
    //             var worldTransform;
    //             var animationDuration;

    //             var isHome = document.getElementById("herocanvas");
    //             if (isHome) {
    //                 canvas = isHome;
    //                 ctx = canvas.getContext("2d");
    //                 height = canvas.height = 400;
    //                 width = canvas.width = 400;
    //                 fov = Math.PI/3;
    //                 fovVertical = fov;
    //                 fovHorizontal = 2 * Math.atan(height*Math.tan(fov/2)/width);
    //                 worldVelocity = {x:0,y:0,z:0,xrot:0.1,yrot:0.3,zrot:0};
    //                 worldTransform = {x:0,y:0,z:6,xrot:0,yrot:0,zrot:0};
    //                 animationDuration = 50;

    //                 //Easing function from:
    //                 //http://gizma.com/easing/
    //                 Math.easeInOutQuint = function (t, b, c, d) {
    //                     t /= d/2;
    //                     if (t < 1) return c/2*t*t*t*t*t + b;
    //                     t -= 2;
    //                     return c/2*(t*t*t*t*t + 2) + b;
    //                 };

    //                 var Point = function(x, y, z){
    //                   this.x = x;
    //                   this.y = y;
    //                   this.z = z;
    //                 };
    //                 Point.prototype.project = function(){
    //                   return new Point(
    //                       width*(this.x/(this.z*2*Math.tan(fovVertical/2)) + 1/2),
    //                       height*(this.y/(this.z*2*Math.tan(fovHorizontal/2)) + 1/2)
    //                   );
    //                 };
    //                 Point.prototype.rotateX = function(theta){
    //                   return new Point(
    //                     this.x,
    //                       this.y * Math.cos(theta) - this.z * Math.sin(theta),
    //                       this.y * Math.sin(theta) + this.z * Math.cos(theta)
    //                     );
    //                 };
    //                 Point.prototype.rotateY = function(theta){
    //                   return new Point(
    //                       this.x * Math.cos(theta) - this.z * Math.sin(theta),
    //                       this.y,
    //                       this.x * Math.sin(theta) + this.z * Math.cos(theta)
    //                     );
    //                 };
    //                 Point.prototype.rotateZ = function(theta){
    //                     return new Point(
    //                       this.x * Math.cos(theta) - this.y * Math.sin(theta),
    //                       this.x * Math.sin(theta) + this.y * Math.cos(theta),
    //                       this.z
    //                     );
    //                 };
    //                 Point.prototype.translate = function(x, y, z){
    //                   return new Point(
    //                     this.x + x,
    //                     this.y + y,
    //                     this.z + z
    //                   );
    //                 };

    //                 var queue = [];
    //                 var queueQuad = function(point0, point1, point2, point3, colour){
    //                     var averageZ = (point0.z + point1.z + point2.z + point3.z) / 4;
    //                     var element = [colour, averageZ, point0.project(), point1.project(), point2.project(), point3.project()];
    //                     if(queue.length === 0)
    //                         queue[0] = element;
    //                     else{
    //                         queue[queue.length] = element;
    //                         for(var x = queue.length - 2;x >= 0 && queue[x][1] < averageZ;x -= 1){
    //                             queue[x + 1] = queue[x];
    //                             queue[x] = element;
    //                         }
    //                     }
    //                 };
    //                 var clearDrawQueue = function(){
    //                     queue = [];
    //                 };
    //                 var drawQueue = function(){
    //                     for(var x = 0;x < queue.length;x++){
    //                         ctx.fillStyle = queue[x][0];
    //                         ctx.strokeStyle = "#000";
    //                         ctx.lineWidth = 5;
    //                         ctx.beginPath();
    //                         ctx.moveTo(queue[x][2].x, queue[x][2].y);
    //                         for(var y = 3;y < queue[x].length;y++)
    //                             ctx.lineTo(queue[x][y].x, queue[x][y].y);
    //                         ctx.lineTo(queue[x][2].x, queue[x][2].y);
    //                         ctx.fill();
    //                         ctx.stroke();
    //                     }
    //                 };

    //                 var faces = [[0,1,3,2],[0,4,5,1],[1,5,7,3],[2,3,7,6],[0,2,6,4],[4,6,7,5]];
    //                 //White orange green red blue yellow grey(inner sides)
    //                 var faceColours = ["#05F02C","#FD811C","#FFFFFF","#FFFF00","#006CE0","#FF120A","#000000"];
    //                 //"#FFFFFF","#FD811C","#05F02C","#FF120A","#006CE0","#FFFF00"
    //                 var Cube = function(xpos, ypos, zpos, rotx, roty, rotz){
    //                     this.x = xpos;
    //                     this.y = ypos;
    //                     this.z = zpos;
    //                     this.rotx = rotx;
    //                     this.roty = roty;
    //                     this.rotz = rotz;

    //                     this.faceMatrix = [0,1,2,3,4,5];

    //                     if(xpos < 1)
    //                         this.faceMatrix[5] = 6;
    //                     if(xpos >= 0)
    //                         this.faceMatrix[0] = 6;
    //                     if(ypos < 1)
    //                         this.faceMatrix[3] = 6;
    //                     if(ypos >= 0)
    //                         this.faceMatrix[1] = 6;
    //                     if(zpos < 1)
    //                         this.faceMatrix[2] = 6;
    //                     if(zpos >= 0)
    //                         this.faceMatrix[4] = 6;

    //                     this.points = [];
    //                     for(var x = -0.5;x <= 0.5;x++)
    //                         for(var y = -0.5;y <= 0.5;y++)
    //                             for(var z = -0.5;z <= 0.5;z++)
    //                                 this.points.push(new Point(x, y, z));
    //                 };
    //                 Cube.prototype.queue = function(){
    //                     for(var x = 0;x < 6;x++){
    //                         var quad = [];
    //                         for(var y = 0;y < 4;y++){
    //                             var point = this.points[faces[x][y]];
    //                             point = point.translate(this.x, this.y, this.z).rotateX(this.rotx).rotateY(this.roty).rotateZ(this.rotz).rotateX(worldTransform.xrot).rotateY(worldTransform.yrot).rotateZ(worldTransform.zrot).translate(worldTransform.x, worldTransform.y, worldTransform.z);
    //                             quad.push(point);
    //                         }
    //                         queueQuad(quad[0],quad[1],quad[2],quad[3],faceColours[this.faceMatrix[x]]);
    //                     }
    //                 };
    //                 Cube.prototype.rotateColours = function(xrot, yrot, zrot){
    //                     for(var x = 0;x < xrot;x++)
    //                         this.faceMatrix = [this.faceMatrix[0],this.faceMatrix[2],this.faceMatrix[3],this.faceMatrix[4],this.faceMatrix[1],this.faceMatrix[5]];
    //                     for(var x = 0;x < yrot;x++)
    //                         this.faceMatrix = [this.faceMatrix[2],this.faceMatrix[1],this.faceMatrix[5],this.faceMatrix[3],this.faceMatrix[0],this.faceMatrix[4]];
    //                     for(var x = 0;x < zrot;x++)
    //                         this.faceMatrix = [this.faceMatrix[3],this.faceMatrix[0],this.faceMatrix[2],this.faceMatrix[5],this.faceMatrix[4],this.faceMatrix[1]];
    //                 };

    //                 var slices = [[2 , 1 , 0 , 5 , 4 , 3 , 8 , 7 , 6 ],
    //                               [11, 10, 9 , 14, 13, 12, 17, 16, 15],
    //                               [20, 19, 18, 23, 22, 21, 26, 25, 24],
    //                               [2 , 1 , 0 , 11, 10, 9 , 20, 19, 18],
    //                               [5 , 4 , 3 , 14, 13, 12, 23, 22, 21],
    //                               [8 , 7 , 6 , 17, 16, 15, 26, 25, 24],
    //                               [6 , 3 , 0 , 15, 12, 9 , 24, 21, 18],
    //                               [7 , 4 , 1 , 16, 13, 10, 25, 22, 19],
    //                               [8 , 5 , 2 , 17, 14, 11, 26, 23, 20]];

    //                 var Rubiks = function(){
    //                     this.cubies = [];
    //                     for(var x = -1;x <= 1;x++)
    //                         for(var y = -1;y <= 1;y++)
    //                             for(var z = -1;z <= 1;z++)
    //                                 this.cubies.push(new Cube(x, y, z, 0, 0, 0));
    //                 };
    //                 Rubiks.prototype.queue = function(){
    //                     if(this.animationTimer > 0)
    //                         this.updateAnimation();
    //                     else if(this.animationTimer == 0){
    //                         this.endAnimation();
    //                         this.fixColours();
    //                         this.startAnimation();
    //                     }
    //                     else if(!this.animationTimer)
    //                         this.startAnimation();
    //                     for(var x = 0;x < this.cubies.length;x++){
    //                         this.cubies[x].queue();
    //                     }
    //                 };
    //                 Rubiks.prototype.startAnimation = function(){
    //                     this.slice = parseInt(Math.random()*6);
    //                     this.rotationAmount = (parseInt(Math.random()*2) + 1);
    //                     if(Math.random() * 2 > 1)
    //                         this.rotationAmount *= -1;
    //                     this.animationTimer = animationDuration;
    //                 };
    //                 Rubiks.prototype.updateAnimation = function(){
    //                     this.animationTimer--;
    //                     var currentRotation = Math.easeInOutQuint(animationDuration - this.animationTimer, 0, this.rotationAmount * Math.PI / 2, animationDuration);
    //                     for(var x = 0;x < 9;x++){
    //                         if(this.slice < 3)
    //                             this.cubies[slices[this.slice][x]].rotx = currentRotation;
    //                         else if(this.slice < 6)
    //                             this.cubies[slices[this.slice][x]].roty = currentRotation;
    //                         else
    //                             this.cubies[slices[this.slice][x]].rotz = currentRotation;
    //                     }
    //                 };
    //                 Rubiks.prototype.endAnimation = function(){
    //                     var value = this.rotationAmount;
    //                     if(value == -1)
    //                         value = 3;
    //                     else if(value == -2)
    //                         value = 2;


    //                     for(var x = 0;x < 9;x++){

    //                         if(this.slice < 3)
    //                             this.cubies[slices[this.slice][x]].rotateColours(value, 0, 0);
    //                         else if(this.slice < 6)
    //                             this.cubies[slices[this.slice][x]].rotateColours(0, value, 0);
    //                         else
    //                             this.cubies[slices[this.slice][x]].rotateColours(0, 0, value);

    //                         this.cubies[slices[this.slice][x]].rotx = 0;
    //                         this.cubies[slices[this.slice][x]].roty = 0;
    //                         this.cubies[slices[this.slice][x]].rotz = 0;
    //                     }
    //                 };
    //                 Rubiks.prototype.fixColours = function(){
    //                     var value = this.rotationAmount;
    //                     if(value == -1)
    //                         value = 3;
    //                     else if(value == -2)
    //                         value = 2;

    //                     for(var x = 0;x < value;x++){
    //                         var temp = this.cubies[slices[this.slice][0]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][0]].faceMatrix = this.cubies[slices[this.slice][6]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][6]].faceMatrix = this.cubies[slices[this.slice][8]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][8]].faceMatrix = this.cubies[slices[this.slice][2]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][2]].faceMatrix = temp.slice();

    //                         temp = this.cubies[slices[this.slice][1]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][1]].faceMatrix = this.cubies[slices[this.slice][3]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][3]].faceMatrix = this.cubies[slices[this.slice][7]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][7]].faceMatrix = this.cubies[slices[this.slice][5]].faceMatrix.slice();
    //                         this.cubies[slices[this.slice][5]].faceMatrix = temp.slice();
    //                     }
    //                 };

    //                 var main = new Rubiks();

    //                 var draw = function(){
    //                     ctx.clearRect(0, 0, width, height);

    //                     worldTransform.x += worldVelocity.x;
    //                     worldTransform.y += worldVelocity.y;
    //                     worldTransform.z += worldVelocity.z;


    //                     // worldTransform.xrot += worldVelocity.xrot + 0.005;
    //                     // worldTransform.yrot += worldVelocity.yrot + 0.01;
    //                     // worldTransform.zrot += worldVelocity.zrot;

    //                     worldVelocity.xrot /= 1.03;
    //                     worldVelocity.yrot /= 1.06;

    //                     clearDrawQueue();
    //                     //queue shit here
    //                     main.queue();
    //                     drawQueue();

    //                     requestAnimationFrame(draw);
    //                 };

    //                 requestAnimationFrame(draw);
    //             }

    //         } else {
    //             // console.log('iniHeroCubeAnimation(): unable to toggle hero-cube-img class to hero-cube-canvas');
    //         }

    //     } else {
    //         // console.log("iniHeroCubeAnimation(): Error loading the animation => element not present");
    //     }
    // }


    // function iniHeroCube() {
    //     if (window.matchMedia('(display-mode: standalone)').matches) {

    //         console.log("is installed app => run canvas");
    //         iniHeroCubeAnimation();

    //     } else {

    //         var isMobile = false; //initiate as false
    //         // device detection
    //         if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //             || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    //             isMobile = true;
    //         }

    //         if (isMobile) {
    //             // console.log("is mobile on web => do nothing");
    //         } else {
    //             // console.log("is not mobile => run canvas");
    //             iniHeroCubeAnimation();
    //         }

    //     }
    // }
    // iniHeroCube();
    







    function getSinceDate(date) {
        var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        var todayDate = new Date();
        var diffDays = (Math.round(Math.abs((todayDate.getTime() - date.getTime())/(oneDay))) - 1);

        // console.log("DIFFDAYS: " + diffDays);

        if (diffDays < 1) {
            // console.log('Today');
            return 'Today'
        } else if (diffDays === 1) {
            // console.log('Yesterday');
            return 'Yesterday'
        } else if (diffDays > 365) {
            if (Math.floor(diffDays / 365) > 1) {
                // console.log(Math.floor(diffDays / 365) + ' years ago');
                return Math.floor(diffDays / 365) + ' years ago'
            } else {
                // console.log(Math.floor(diffDays / 365) + ' year ago');
                return Math.floor(diffDays / 365) + ' year ago'
            }
        } else {
            // console.log(diffDays + ' days ago');
            return diffDays + ' days ago'
        }
    }

    if($('.js-log-date').length) {
        $('.js-log-date').each( function() {
            var logDate = new Date($(this).text());
            $(this).text(getSinceDate(logDate));
        });
    }



});

